<template>
  <!--  <ul>-->
  <!--    <li :key="column.label" v-for="column in columns">-->
  <!--      {{ getData(column.data) }}-->
  <!--    </li>-->
  <!--  </ul>-->
  <!--  <el-table :data="remote.data" style=" width: 100%">-->
  <!--    <el-table-column v-for="column in columns" :prop="getData(column.)" label="Name" min-width="14">-->
  <!--      <el-table>-->

  <el-table
    style="width: 100%"
    :max-height="height ? height : false"
    :data="results"
  >
    <el-table-column
      :key="column.name"
      v-for="column in columns"
      :prop="column.value"
      :fixed="column.fixed"
      :label="column.label"
      :width="column.width"
    >
      <template v-slot="scope">
        <slot
          :name="column.value"
          :value="getPropertyByString(scope.row, column.value)"
          :item="scope.row"
          :scope="scope"
        >
          <div
            v-if="column.render"
            v-html="
              column.render(
                scope.row,
                getPropertyByString(scope.row, column.value)
              )
            "
          ></div>
          <div v-else>{{ getPropertyByString(scope.row, column.value) }}</div>
        </slot>
      </template>
    </el-table-column>
  </el-table>
  <div class="d-flex justify-content-end mt-3">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="perPage"
      :current-page="currentPage"
      :total="cTotal"
      layout="total, sizes, prev, pager, next"
    >
    </el-pagination>
  </div>
</template>

<script>
import ApiService from "../core/services/ApiService.ts";

export default {
  name: "RemoteTable",
  props: ["columns", "data", "total", "remoteUrl", "height"],
  emits: ["sizeChanged", "currentChanged"],
  computed: {
    results() {
      return this.data || this.resulted.data;
    },
    cTotal() {
      return this.total || this.resulted.total;
    },
  },
  data: function () {
    return {
      perPage: 10,
      currentPage: 1,
      resulted: {
        data: [],
        total: 0,
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      if (!this.remoteUrl) {
        this.resulted.data = this.data;
        return;
      }
      ApiService.get(this.remoteUrl, null, {
        params: {
          page: this.currentPage,
          perPage: this.perPage,
        },
      }).then(({ data }) => {
        this.resulted.data = data.data;
        console.log(data);
        this.resulted.total = data.meta.total;
        this.$emit("totalChanged", this.resulted.total);
      });
    },
    getPropertyByString(object, propString) {
      let value = object;

      const props = propString.split(".");
      for (let index = 0; index < props.length; index += 1) {
        if (props[index] === undefined) break;
        try {
          value = value[props[index]];
        } catch (error) {
          return undefined;
        }
      }
      return value;
    },
    handleSizeChange(perPage) {
      console.log("sizeChanged", perPage);
      this.$emit("sizeChanged", perPage);
      this.perPage = perPage;
      this.loadData();
    },
    handleCurrentChange(page) {
      this.$emit("currentChanged", page);
      this.currentPage = page;
      this.loadData();
    },
  },
};
</script>

<style scoped></style>
